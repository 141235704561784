/* .notification-container {
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
} */
p{
    font-family: "Figtree", sans-serif;
}
.notification-header-title {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.notification-tab-container {
    margin-top: 23px;
    width: 100%;
}

.notification-tab-header-view {
    width: 100%;
}

.notification-tab-header-container {
    width: 100%;
    background-color: #1D1D1D;
    border-radius: 7px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.notification-tab-option {
    width: 64px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    cursor: pointer;
}


.notification-tab-option p {
    font-size: 14px;
    font-weight: 400;
    color: #A5A5A5;
    line-height: 16.8px;
}


.notification-items-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 21px 12px;
    gap: 12px;
    cursor: pointer;
}

.notification-items-icon-view {
    width: 3%;
    display: flex;
    align-items: center;
    justify-content: center
}

.notification-items-icon {
    width: 32px;
    height: 32px;
}

.notification-info-view {
    width: 97%;
}

.notification-info-title {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.notification-info-description {
    color: #B3B4B3;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.notification-items-action-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.notification-items-action-view .notification-time{
    white-space: pre;
}