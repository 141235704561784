p,
li,
h1,
h2,
h3,
h4,
h5 {
    font-family: "Figtree", sans-serif;
}


.profile-header-title {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.profile-tab-container {
    margin-top: 23px;
    width: 100%;
}

.profile-tab-header-view {
    width: 100%;
}

.profile-tab-container {
    margin-top: 23px;
    width: 100%;
}

.profile-tab-header-view {
    width: 100%;
}

.profile-tab-header-container {
    width: 100%;
    background-color: #1D1D1D;
    border-radius: 7px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
}

.profile-tab-option {
    width: auto;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    cursor: pointer;
}


.profile-tab-option p {
    font-size: 14px;
    font-weight: 400;
    color: #A5A5A5;
    line-height: 16.8px;
}


.profile-form-container {
    width: 100%;
    background-color: #181818;
    padding: 20px;
    border-radius: 12px;
}

.profile-form-title {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21.6px;
}

.profile-image-upload-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.profile-image-upload-container .image-section {
    width: 10%;
}

.profile-image-upload-container .image-section img {
    width: 72px;
    height: 72px;
    border-radius: 100px;
    object-fit: cover;
}

.profile-img-upload-section {
    width: 90%;
    background: #0D0D0D;
    border-radius: 16px;
    cursor: pointer;
    padding: 22.4px 16px;
    transition: background-color 0.3s, border 0.3s;
}

.profile-img-upload-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 100%;
}

.profile-img-upload-section:hover {
    opacity: 0.5;
    border-color: #A7FC00;
    cursor: pointer;
}

.profile-img-upload-section.dragover {
    opacity: 0.5;
    border-color: #A7FC00;
}

.profile-img-upload-view .click-text {
    font-size: 16px;
    margin-top: 24px;
    color: #FFFFFF;
    font-weight: 400;
}

.profile-img-upload-view .file-type {
    font-size: 14px;
    margin-top: 4px;
    color: #4A4A4A;
    font-weight: 400;
}

.save-view {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.cancel-button {
    background: transparent;
    color: #FFFFFF;
    width: 72px;
    padding: 8px 14px;
    border-radius: 12px;
    border: 1px solid #4A4A4A;
    font-size: 14px;
    font-weight: 500;
    outline: none;
}

.save-button {
    color: #030500;
    width: 115px;
    padding: 8px 0px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    background-color: #A7FC00;
}

.preference-view,
.personal-info-view,
.profile-password-view {
    width: 100%;
    margin-top: 24px;
}

.preference-container {
    width: 100%;
    background-color: #181818;
    border-radius: 12px;
    padding: 20px;

}

.preference-title {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21.6px;
}

.preference-list-view {
    width: 100%;
    margin-top: 9px;
}

.preference-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0px;
    /* padding-bottom: 17px; */
}

.preference-list p {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21.6px;
}

.preference-list img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.preference-country-view {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.preference-country-view p {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21.6px;
}

.preference-country-view img {
    width: 24px;
    height: 18px;
}


.disabled-btn {
    opacity: 0.4;
}