p {
    font-family: "Figtree", sans-serif;
}

.settings-header-title {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.settings-tab-container {
    margin-top: 23px;
    width: 100%;
}

.settings-tab-header-view {
    width: 100%;
}

.settings-tab-header-container {
    width: 100%;
    background-color: #1D1D1D;
    border-radius: 7px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.settings-tab-option {
    width: auto;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    cursor: pointer;
}


.settings-tab-option p {
    font-size: 14px;
    font-weight: 400;
    color: #A5A5A5;
    line-height: 16.8px;
}

.settings-form-container {
    width: 100%;
    background-color: #181818;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 16px;
}

.settings-form-container .title-container {
    width: 100%;
    margin-bottom: 16px;
}

.settings-form-container .title-container p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #FFFFFF;
}

.settings-form-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 16px;
}

.settings-input {
    width: 50%;
}

.delete-text {
    font-weight: 400;
    font-size: 14px;
    color: #BA2543;
}

.add-company-portfolio-container {
    width: 100%;
    border-top: 1px solid #1D1D1D;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    padding-top: 26px;
    padding-bottom: 12px;
}

.add-company-portfolio-container p {
    color: #A7FC00;
    font-size: 14px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.action-btn-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}


.prev-next-view {
    width: 50%;
}

.save-view {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}