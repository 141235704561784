@font-face {
    font-family: 'OpenSauceSans';
    src: url('../assets/fonts/OpenSauceSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'OpenSauceSans';
    src: url('../assets/fonts/OpenSauceSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'OpenSauceSans';
    src: url('../assets/fonts/OpenSauceSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'OpenSauceSans';
    src: url('../assets/fonts/OpenSauceSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  /* Apply the font to the entire page */
  body {
    font-family: 'OpenSauceSans', sans-serif;
  }
  