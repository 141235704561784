@media screen and (max-width: 567px) {
  body {
    overflow-x: hidden;
  }
  .body {
    padding-left: 5.1%;
    padding-right: 5.1%;
    background-image: none;
    overflow-x: hidden;
  }
  .body2 {
    padding-left: 5.1%;
    padding-right: 5.1%;
    background-image: none;
    overflow-x: hidden;
  }
  .home-body{
    min-height: auto !important;
  }
  .navbar {
    width: 100% !important;
    background-color: #000 !important;
    border-radius: 0;
    padding: 0;
    border: none;
    height: 46px;
    position: fixed;
    padding-left: 5.1%;
    padding-right: 5.1%;
    margin-top: 0;
    padding-top: 16px;
    padding-bottom: 36px !important;
    z-index: 100;
    transition: all 0.5s ease;
    align-items: flex-start;
  }
  .expandNav {
    width: 100%;
    height: 100vh;
  }
  .textCard {
    margin-top: 102px;
  }
  .textCard p {
    font-size: 12px;
    line-height: 18px;
  }
  .bannerText {
    font-size: 35px;
    margin-top: 16px;
    text-align: center;
    line-height: 48px;
  }
  .bannerText2 {
    font-size: 35px;
    margin-top: 16px;
    line-height: 48px;
  }
  .typer {
    font-size: 22px;
    height: auto !important;
    /* background-color: red; */
  }
  .desTextCont {
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .desTextCont2 {
    margin-top: 16px;
    padding-right: 20px;
  }
  .desTextContInner {
    width: 100% !important;
  }
  .desTextContInner2 {
    width: 100% !important;
  }
  .desText {
    font-size: 14px;
    line-height: 32px;
  }
  .desText2 {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  .btn-Wrap {
    margin-top: 40px;
    width: 170px !important;
  }
  .primaryBtn {
    padding-left: 25px;
    padding-right: 25px;
  }
  .primaryBtn p {
    font-size: 16px;
  }
  .partners-sec {
    margin-top: 54px !important;
  }
  .orp {
    font-size: 16px;
  }
  .partners {
    justify-content: flex-start;
    margin-top: 24px !important;
  }
  .typer-holder {
    height: 30px;
    width: 90vw;
    /* background-color: red !important; */
  }
  .bby {
    margin-top: 88px !important;
    width: 100% !important;
  }
  .bn-img {
    margin-top: 54px !important;
  }
  .bby h4 {
    font-size: 32px;
    line-height: 38px !important;
  }
  .bk {
    margin-top: 24px;
  }
  .backed-by {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 27px;
  }
  .footer {
    min-height: 610px !important;
    margin-top: 56px;
    background-image: none;
    padding-left: 5.1%;
    padding-right: 5.1% !important;
    position: relative;
  }
  .b1-sec {
    padding-left: 5.1%;
    padding-right: 5.1%;
  }
  .footer-cont {
    gap: 58px;
    margin-top: 32px;
    /* background-color: red; */
    width: 100% !important;
    margin-bottom: 15px;
  }
  .f-link-section {
    gap: 40px;
    flex-wrap: wrap;
  }
  .card1 {
    width: 100%;
  }
  .card4 {
    margin-top: 58px;
  }
  .trx {
    font-family: "ClashGrotesk-Medium";
    width: 100%;
    font-size: 120px;
    background-size: cover;
    color: rgba(255, 255, 255, 0.05);
    height: 135px;
    /* background-color: red; */
  }
  .copyright-holder {
    width: 90%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
  }
  .menu-content {
    width: 90%;
    position: absolute;
    top: 23.1vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .nav-links1 {
    flex-direction: column-reverse;
    margin-top: 105px;
  }
  .lgLink2 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .nav-item {
    font-weight: 500 !important;
    font-size: 16px;
  }
  .bt {
    margin-top: 40px;
    font-size: 32px;
  }
  .tkbody {
    padding-left: 5.1% !important;
    padding-right: 5.1% !important;
  }
  .list-item-cont {
    margin-top: 26px;
    row-gap: 24px;
  }
  .list-card {
    width: 100%;
    height: auto !important;
    /* min-height: 188px; */
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .list-card h4 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 16px;
  }
  .list-card p {
    margin-top: 12px;
    font-size: 16px;
  }
  .tk-reg-body {
    padding-left: 5.1%;
    padding-right: 5.1%;
  }
  .pageCont {
    width: 100%;
  }
  .pageH4 {
  }
  .inputCont {
    margin-top: 32px;
  }
  .inputCont1 {
    margin-top: 16px;
  }
  .checkCont p {
    width: 80vw;
    font-size: 14px;
  }
  .bottomCont {
    margin-top: 24px;
  }
  .sec-btnHolder {
    width: 100vw;
    background: #000;
    border-top: 1px solid #1d1d1d;
    position: fixed !important;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 16px;
  }
  .w-80 {
    width: 85%;
  }
  .textarea {
    min-height: 132px !important;
  }
}
